import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "@/styles/index.less"

import React from "react"
import ReactDOM from "react-dom"

import App from "@/pages/App"

import { RollbarProvider } from "./context/RollbarContext"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
  <RollbarProvider>
    <App />
  </RollbarProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
