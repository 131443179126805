import React from "react"
import { Route } from "react-router-dom"

import { RouteInput } from "@/types/route"

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export const RouteWithSubRoutes = (route: RouteInput) => {
  return (
    <Route path={route.path} exact={true}>
      <route.component routes={route.routes} />
    </Route>
  )
}
