import { Tag } from "antd"
import React from "react"

import styles from "./style.module.less"

export const Loading = ({
  error,
  size,
  color = "#666666",
  // actionText is the text in front of the ... icon for labeling what is happening
  actionText = "",
  // spinnerAlignment controls position of spinner by margin, default is CENTRE
  spinnerAlignment = "CENTRE"
}: {
  error?: string
  size?: number
  color?: string
  actionText?: string
  spinnerAlignment?: "LEFT" | "CENTRE"
}) => {
  if (error) {
    return (
      <div className={styles["err-box"]}>
        <Tag color="#f50">{error || "Error!"}</Tag>
      </div>
    )
  }
  let alignMargin: string
  switch (spinnerAlignment) {
    case "LEFT":
      alignMargin = "0 0"
      break
    default:
      alignMargin = "0 auto"
      break
  }
  const bounceStyle = size
    ? { width: `${size}px`, height: `${size}px`, backgroundColor: color }
    : { backgroundColor: color }
  return (
    <div
      className={styles["spinner"]}
      style={{ width: `${70 + actionText.length * 8}px`, margin: alignMargin }}
      data-testid="loading"
    >
      {actionText}
      <div className={styles["bounce1"]} style={bounceStyle} />
      <div className={styles["bounce2"]} style={bounceStyle} />
      <div className={styles["bounce3"]} style={bounceStyle} />
    </div>
  )
}

export default Loading
