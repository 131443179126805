export enum Env {
  "LOCAL" = "localhost",
  "QA" = "qa",
  "SANDBOX" = "sandbox",
  "PRODUCT" = "product"
}
export const getEnv = (host: string) => {
  if (host.includes("chat.sapia.ai")) {
    return Env.PRODUCT
  }

  return (/fi\.([A-Za-z]*)\.?predictivehire\.co/.exec(host) ||
    /fi\.([A-Za-z]*)\.?sapia\.ai/.exec(host) ||
    /chat\.([A-Za-z]*)\.?sapia\.ai/.exec(host) ||
    /(localhost)/.exec(host))?.[1]
}

export enum RegionCode {
  "AU" = "au",
  "EU" = "eu",
  "US" = "us"
}

export enum Region {
  "AU" = "ap-southeast-2",
  "EU" = "eu-west-1",
  "US" = "us-east-1"
}

/**
 * We might have region or region code in url, we will convert them to aws region (chatbot region)
 */
export const convertToChatbotRegion = (regionString: string) => {
  if (regionString === RegionCode.AU) {
    return Region.AU
  } else if (regionString === RegionCode.EU) {
    return Region.EU
  } else if (regionString === RegionCode.US) {
    return Region.US
  }

  return regionString
}
