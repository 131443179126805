import { Input } from "antd"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

import { postOrderAssessment } from "@/apis/FIApis"
import { Loading } from "@/components/Loading"

import PHBackgroundImage from "../../asset/Bias-HandBook-Download-Space.png"
import styles from "./style.module.less"
import { generateMockOrderAssessmentBodyForCustomerDemo } from "./utils"

export const CustomerDemoHub = () => {
  const [showLoading, setShowLoading] = useState(false)
  const [candidateName, setCandidateName] = useState("")
  const [candidateEmail, setCandidateEmail] = useState("")

  const { region, customerId, externalCohortId, predictorMasterId } = useParams<{
    region: string
    customerId: string
    externalCohortId: string
    predictorMasterId: string
  }>()

  const redirectToAssessment = (assessmentId: string) => {
    window.location.href = `${window.location.origin}/${region}/assessments/${assessmentId}`
  }

  const startDemoAssessment = async () => {
    if (!candidateName) {
      alert("Your full name is required!")
      return
    }
    if (!candidateEmail) {
      alert("Your email is required!")
      return
    }
    setShowLoading(true)
    const orderAssessmentBody = generateMockOrderAssessmentBodyForCustomerDemo({
      candidateName,
      candidateEmail,
      customerId,
      externalCohortId,
      predictorMasterId
    })
    const orderRes = await postOrderAssessment({ orderAssessmentBody: orderAssessmentBody, region: region })
    setShowLoading(false)
    redirectToAssessment(orderRes?.data?.assessmentId)
  }

  return (
    <div className={styles["main"]}>
      {showLoading && (
        <div className={styles["loading-box"]}>
          <Loading color="#f92c5e" />
        </div>
      )}
      <img className={styles["bg"]} alt="PredictiveHire bias handbook space background image" src={PHBackgroundImage} />
      <div className={styles["content-box"]}>
        <div className={styles["content"]}>
          <h1 className={styles["title"]}>Sapia Demo</h1>
          <p>Try our interview experience for yourself!</p>
          <div className={styles["handle-btn-box"]}>
            <Input
              placeholder="Enter your full name"
              onChange={e => setCandidateName(e.target.value)}
              style={{ width: 200 }}
              data-cy="usernameInput"
            />
          </div>
          <div className={styles["handle-btn-box"]}>
            <Input
              placeholder="Enter your email"
              onChange={e => setCandidateEmail(e.target.value)}
              style={{ width: 200 }}
              data-cy="emailInput"
            />
          </div>
          <div className={styles["handle-btn-box"]}>
            <button data-cy="selectFiButton" className={styles["demo-button"]} onClick={startDemoAssessment}>
              Begin Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
