import React from "react"
import Rollbar from "rollbar"

import { ENV, ROLLBAR_TOKEN } from "@/constants"

export const RollbarContext = React.createContext<{ rollbar: Rollbar } | undefined>(undefined)

RollbarContext.displayName = "RollbarContext"

// We need to scrub candidateResponse.answer as it may contains PII data
const SCRUB_FIELDS = ["candidateResponse.answer"]

export const RollbarProvider = ({ children }: { children: React.ReactNode }) => {
  const rollbar = new Rollbar({
    accessToken: ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: ENV
    },
    scrubTelemetryInputs: true,
    overwriteScrubFields: true,
    scrubFields: SCRUB_FIELDS
  })

  return <RollbarContext.Provider value={{ rollbar }}>{children}</RollbarContext.Provider>
}
