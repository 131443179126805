export const regionAppIdMap: { [key: string]: string } = {
  "ap-southeast-2": "77ba4974-9d6b-57f7-a993-0df7feafe38e",
  "eu-west-1": "77ba4974-9d6b-57f7-a993-0df7feafe38e",
  "us-east-1": "77ba4974-9d6b-57f7-a993-0df7feafe38e"
}
export const coreFIAuthToken: { [key: string]: string } = {
  localhost:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJUT05ZIiwic3ViIjoiRkktQVBQIiwic2NvcGVzIjpbIkZJIl19.W_8C_iXrMlbWDTVNmtFDUhzv97iaa5vo_BkrueXZiNg",
  dev:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJUT05ZIiwic3ViIjoiRkktQVBQIiwic2NvcGVzIjpbIkZJIl19.4f08_VsfzriLMRSXJxO8mnvCU-ErsmBDnej06sryuOY",
  qa:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJUT05ZIiwic3ViIjoiRkktQVBQIiwic2NvcGVzIjpbIkZJIl19.4f08_VsfzriLMRSXJxO8mnvCU-ErsmBDnej06sryuOY",
  sandbox:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJUT05ZIiwic3ViIjoiRkktQVBQIiwic2NvcGVzIjpbIkZJIl19.TDfENodrlNy8DfOhaxOkevdFT-E4DcsQfO27fXDtXPk"
}
export const phInternalServiceSecret = "heart"
