import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

import { ENV } from "@/constants"
import { RouteWithSubRoutes } from "@/router/router-helper"
import { getRoutes } from "@/router/routes"
export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {getRoutes(ENV).map((route: { component: any; path: string }, i: number) => {
          return <RouteWithSubRoutes key={i} {...route} />
        })}
      </Switch>
    </BrowserRouter>
  )
}
