import "./style.less"

import { PHChatBot } from "@predictivehire/phchatbot"
import { AxiosError } from "axios"
import React from "react"
import { useParams } from "react-router-dom"
import validator from "validator"

import { Domain } from "@/apis/FIApis"
import { regionAppIdMap } from "@/constants/mockDataForWidget"
import { useRollbar } from "@/hooks/useRollbar"

import { convertToChatbotRegion, getEnv, RegionCode } from "./utils"

const containerId = "chatBot"

export const ChatBotInterview = () => {
  // Set cookie to indicate that the user come from Interview to marketing page sapia.ai
  document.cookie = "sapiaInterview=true; Max-Age=15552000; Domain=sapia.ai; Path=/; Secure; SameSite=None"
  const { rollbar } = useRollbar()
  /**
   * get the stage
   * http://app.localhost.com => localhost
   * https://fi.dev.predictivehire.co/ => dev
   * https://fi.qa.predictivehire.co/ => qa
   * https://fi.demo.predictivehire.co/ => demo
   * https://fi.predictivehire.co/ => prod
   */
  const env = (getEnv(window.location.host) as keyof typeof Domain) as any
  const { assessmentId, cohortId, region, jobRequisitionShortcode, applicationShortcode } = useParams<{
    assessmentId?: string
    cohortId?: string
    region?: string
    regionCode?: RegionCode
    jobRequisitionShortcode?: string
    applicationShortcode?: string
  }>()
  const chatbotRegion = convertToChatbotRegion(region)
  const chatbotATS = new PHChatBot({
    env: env,
    appId: regionAppIdMap[chatbotRegion],
    region: chatbotRegion,
    assessmentId: assessmentId,
    cohortId: cohortId,
    showHeader: true,
    jobRequisitionShortcode: jobRequisitionShortcode,
    applicationShortcode: applicationShortcode
  })

  chatbotATS.addCallbackHandler({
    FI_ASSESSMENT_ENDED: (payload: unknown) => {
      console.info("FI_ASSESSMENT_ENDED", payload)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const redirectUrl = urlParams.get("redirect")
      if (redirectUrl) {
        if (validator.isURL(redirectUrl)) {
          window.location.href = redirectUrl
        } else {
          console.info(`Invalid redirect url: ${redirectUrl}`)
        }
      }
      localStorage.removeItem("VIDEO_RETRY_RECORDS")
      localStorage.removeItem("DO_NOT_SHOW_LESS_WORD_COUNT_POP_UP_AGAIN")
    },
    CHATBOT_ERROR: (payload: { chatbotErrorType: string; params: Record<string, unknown>; error: AxiosError }) => {
      const { chatbotErrorType, params, error } = payload || {}
      const rollbarPayload = {
        chatbotErrorType: chatbotErrorType,
        requestParams: params,
        // If sending entire error (config, request, response...) to rollbar, the error data is cut off automatically by rollbar
        // AND error?.response also contains config and request info
        error: error?.response
      }
      rollbar.error(chatbotErrorType, rollbarPayload)
    },
    CHATBOT_LOG: (payload: { chatbotLogType: string; params: Record<string, unknown> }) => {
      // adding chatbot log type (PHChatBotLogType in ph-fi-widget) here if we want to log them in rollbar
      // remove if it's not necessary to log to save rollbar request
      const chatbotLogWhitelist = ["INVALID_REDIRECT_URL", "INVALID_PHONE_NUMBER"]
      const { chatbotLogType, params } = payload || {}
      if (chatbotLogWhitelist.includes(chatbotLogType)) {
        const rollbarPayload = {
          chatbotLogType: chatbotLogType,
          params: params
        }
        rollbar.info(chatbotLogType, rollbarPayload)
      }
    }
  })

  chatbotATS.attachTo({ selector: `#${containerId}` })
  return (
    <div className="main">
      <div className="chat-bot-box" id={containerId}></div>
    </div>
  )
}
