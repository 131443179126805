import { v4 as uuidV4 } from "uuid"

import { parseFullName } from "../Introduce/utils"

/**
 *  Generate mock order assessment body for Customer Demo hub
 */
export const generateMockOrderAssessmentBodyForCustomerDemo = ({
  candidateName,
  candidateEmail,
  customerId,
  externalCohortId,
  predictorMasterId
}: {
  candidateName: string
  candidateEmail?: string
  customerId: string
  externalCohortId: string
  predictorMasterId: string
}) => {
  const { firstName, lastName } = parseFullName(candidateName)

  const orderAssessmentBody = {
    atsApplicationInput: {
      predictorMasterId: predictorMasterId,
      externalApplicantId: uuidV4(),
      externalApplicationId: uuidV4(),
      externalAssessmentId: uuidV4(),
      // This customer demo run when the vacancy/cohort already created, it won't create new cohort -> only customerId externalId matter here to check the existence other cohortInfo won't be used
      cohortInfo: {
        customerId: customerId,
        ownerUserId: "5fc771bb46269ed7679ea600",
        externalId: externalCohortId,
        title: "Customer Demo Hub Cohort",
        description: "Customer Demo Hub Cohort description",
        managers: [
          {
            externalId: "atsdemomanager@predictivehire.com",
            firstName: "ATS Demo",
            lastName: "Manager",
            email: "atsdemomanager@predictivehire.com"
          }
        ]
      },
      candidateInfo: {
        personalInformation: {
          firstName: firstName,
          // Using date time here to make candidate unique for testing purpose
          lastName: lastName || undefined,
          email: candidateEmail,
          phoneNumber: `+61${new Date().getTime()}`
        },
        location: {
          address: "Melbourne",
          coordinate: {
            lat: 17.0,
            lng: -34.0
          }
        }
      }
    }
  }
  return orderAssessmentBody
}
