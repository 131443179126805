import React from "react"

import { RollbarContext } from "@/context/RollbarContext"

export const useRollbar = () => {
  const context = React.useContext(RollbarContext)

  if (context === undefined) {
    throw new Error("useRollbar must be used within a RollbarContext")
  }

  return context
}
