import { v4 as uuidV4 } from "uuid"
export enum AssessmentType {
  FI = "FI",
  CI = "CI",
  SI = "SI"
}
export type CustomerItem = {
  id: string
  name: string
  customerInfo: Customer
}

type Customer = {
  id: string
  fiPredictorMaster: string
  siPredictorMaster?: string
  workflowId?: string
  externalCohortId: string
  cohortTitle?: string
}

export type Region = {
  id: string
  displayName: string
  code: string
}

export type CustomerEnvMap = {
  localhost: CustomerItem[]
  qa: CustomerItem[]
}
export type CustomerRegionMap = {
  sydney: CustomerEnvMap
  ireland: CustomerItem[]
}

export const regionList = [
  {
    id: "1",
    displayName: "Sydney",
    code: "ap-southeast-2"
  },
  {
    id: "2",
    displayName: "Ireland",
    code: "eu-west-1"
  }
]

export const localCustomer = {
  id: "57a02bed3b5ef24805d5d254",
  fiPredictorMaster: "60e17802b7744a25580e4b3b",
  siPredictorMaster: "60e1761033116a182970993a",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b10"
}

export const wooliesQACustomer = {
  id: "5c09fadce98c7601004e5b31",
  fiPredictorMaster: "60e17c8eb7744a25580e4b3c",
  siPredictorMaster: "60e17c784619666197642792",
  workflowId: "610f6812d675838f3190bd5f",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b11"
}

export const wooliesIndigenousQACustomer = {
  id: "5c09fadce98c7601004e5b31",
  fiPredictorMaster: "61a86c393e08c26ba44840c3",
  siPredictorMaster: "61a86c44b8558f163c3172e7",
  workflowId: "61a8706bb724e6924b3b49a3",
  externalCohortId: "034d5bbc-00bc-557a-bca0-b16ba8d5a452",
  cohortTitle: "Woolworths Supermarket Indigenous"
}

export const wooliesMetroQACustomer = {
  id: "6107f7a3185c1e4282509fab",
  fiPredictorMaster: "610800536b11d739a107c65e",
  workflowId: "610f6812d675838f3190bd63",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b12"
}

export const wooliesBigWQACustomer = {
  id: "6107f7b06b11d739a107c65c",
  fiPredictorMaster: "6108a2586b11d739a107c661",
  workflowId: "610f6812d675838f3190bd65",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b13"
}

export const wooliesBigWIndigenousQACustomer = {
  id: "6107f7b06b11d739a107c65c",
  fiPredictorMaster: "61a86c69a858f612e431f934",
  siPredictorMaster: "61a86c71671e443490237968",
  workflowId: "61a87120ddc636f515261a77",
  externalCohortId: "e5e9d417-3215-5daf-9ce1-b588290aa8ec",
  cohortTitle: "BigW Indigenous"
}

export const wooliesDanMurphyQACustomer = {
  id: "6107f7b6dcfb8129123a40ef",
  fiPredictorMaster: "6108a295dcfb8129123a40f2",
  workflowId: "610f6812d675838f3190bd69",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b14"
}
export const wooliesBwsQACustomer = {
  id: "6107f7beccc8aa59bc088a29",
  fiPredictorMaster: "6108a31791f44c79c121452b",
  workflowId: "610f6812d675838f3190bd6d",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b15"
}

export const nibAusQACustomer = {
  id: "6139b7b29dbc58b7b632688c",
  fiPredictorMaster: "613c7b968d4da867250fca9c",
  workflowId: "613d9c0f07a6d44d61791500",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b16"
}

export const phAusDemoQACustomer = {
  id: "57a02bed3b5ef24805d5d254",
  fiPredictorMaster: "60e17802b7744a25580e4b3b",
  siPredictorMaster: "60e1761033116a182970993a",
  workflowId: "610f6812d675838f3190bd5e",
  externalCohortId: "051c0d46-d7b2-4102-a31a-78115daf5b17"
}

export const localCustomerList: CustomerItem[] = [
  {
    id: "1",
    customerInfo: localCustomer,
    name: "Local Customer"
  }
]

export const sydneyQACustomerList: CustomerItem[] = [
  {
    id: "1",
    customerInfo: wooliesQACustomer,
    name: "Woolworths"
  },
  {
    id: "2",
    customerInfo: phAusDemoQACustomer,
    name: "PH Demo"
  },
  {
    id: "3",
    customerInfo: wooliesMetroQACustomer,
    name: "Metro"
  },
  {
    id: "4",
    customerInfo: wooliesBigWQACustomer,
    name: "Big W"
  },
  {
    id: "5",
    customerInfo: wooliesDanMurphyQACustomer,
    name: "Dan Murphy's"
  },
  {
    id: "6",
    customerInfo: wooliesBwsQACustomer,
    name: "BWS"
  },
  {
    id: "7",
    customerInfo: nibAusQACustomer,
    name: "nib Australia"
  },
  {
    id: "8",
    customerInfo: wooliesIndigenousQACustomer,
    name: "Woolworths Supermarket Indigenous"
  },
  {
    id: "9",
    customerInfo: wooliesBigWIndigenousQACustomer,
    name: "BigW Indigenous"
  }
]

export const predictiveHireEUQACustomer = {
  id: "615521e7b0c0c84318b2db7e",
  fiPredictorMaster: "61f8b8487fafc22f667ca144",
  siPredictorMaster: "61f8b83f44ade122460c4bb1",
  workflowId: "61f921f74b9bd539ad613ca6",
  externalCohortId: "2b41859a-6c4a-5fbc-b3d5-c6228955ca69"
}

export const irelandQACustomerList: CustomerItem[] = [
  {
    id: "1",
    customerInfo: predictiveHireEUQACustomer,
    name: "PredictiveHire EU"
  }
]

export const sydneyCustomerEnvMap = {
  localhost: localCustomerList,
  dev: sydneyQACustomerList,
  qa: sydneyQACustomerList,
  // Cuz seed data in QA, SANDBOX, and PRODUCT will be the same, so we can reuse QA customer list
  sandbox: sydneyQACustomerList
}
export const irelandCustomerEnvMap = {
  localhost: localCustomerList,
  qa: irelandQACustomerList,
  sandbox: irelandQACustomerList
}

export const customerRegionMap = {
  sydney: sydneyCustomerEnvMap,
  ireland: irelandCustomerEnvMap
}

export const parseFullName = (fullName: string) => {
  if (!fullName) return
  // Remove space around string and replace multiple space by 1 then split to make sure space is not counted as a word
  fullName = fullName?.trim()?.replace(/\s\s+/g, " ")
  const words = fullName && fullName.split(" ")
  const [firstName, ...lastNameWordList] = words
  const lastName = lastNameWordList?.length > 0 ? lastNameWordList.join(" ") : undefined
  return { firstName: firstName?.trim(), lastName: lastName?.trim() }
}

/**
 * Generate mock order assessment body for Sapia hub
 */
export const generateMockOrderAssessmentBody = ({
  candidateName,
  candidateEmail,
  customer,
  type
}: {
  candidateName: string
  candidateEmail?: string
  customer: Customer
  type: AssessmentType
}) => {
  const { firstName, lastName } = parseFullName(candidateName)

  const predictorMasterId =
    type === AssessmentType.FI || type === AssessmentType.CI ? customer.fiPredictorMaster : customer.siPredictorMaster
  const orderAssessmentBody = {
    atsApplicationInput: {
      predictorMasterId: predictorMasterId,
      externalApplicantId: uuidV4(),
      externalApplicationId: uuidV4(),
      externalAssessmentId: uuidV4(),
      cohortInfo: {
        customerId: customer.id,
        ownerUserId: "5fc771bb46269ed7679ea600",
        externalId: customer.externalCohortId,
        title: customer?.cohortTitle || "Test cohort",
        description: "Test cohort description",
        managers: [
          {
            externalId: "atsdemomanager@predictivehire.com",
            firstName: "ATS Demo",
            lastName: "Manager",
            email: "atsdemomanager@predictivehire.com"
          }
        ]
      },
      candidateInfo: {
        personalInformation: {
          firstName: firstName,
          // Using date time here to make candidate unique for testing purpose
          lastName: `${lastName}${new Date().getTime()}`,
          email: candidateEmail || `${firstName}+test+${new Date().getTime()}@predictivehire.com`,
          phoneNumber: `+61${new Date().getTime()}`
        },
        location: {
          address: "Melbourne",
          coordinate: {
            lat: 17.0,
            lng: -34.0
          }
        }
      },
      atsInfo: {
        name: "EDGE",
        metaData: {
          workflowId: customer.workflowId
        }
      }
    }
  }
  return orderAssessmentBody
}
