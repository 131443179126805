import axios, { AxiosInstance } from "axios"

import { coreFIAuthToken, phInternalServiceSecret } from "@/constants/mockDataForWidget"
import { getEnv } from "@/features/ChatBotInterview/utils"

export enum Domain {
  localhost = "http://app.localhost.com:5003",
  dev = "https://fi-api.dev.predictivehire.com",
  qa = "https://fi-api.qa.predictivehire.com",
  sandbox = "https://fi-api.sandbox.predictivehire.com",
  product = "https://fi-api.product.predictivehire.com"
}

export let axiosInstance: AxiosInstance

export const postOrderAssessment = async ({
  orderAssessmentBody,
  agentType,
  region
}: {
  orderAssessmentBody: unknown
  agentType?: string
  region: string
}) => {
  const env = getEnv(window.location.host) as keyof typeof Domain

  if (!axiosInstance) {
    axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${coreFIAuthToken[env]}`,
        hire: phInternalServiceSecret
      }
    })
  }

  const endpoint = `${Domain[env]}/api/${region}/chatbot/order/ats`
  const url = agentType ? `${endpoint}?agentType=${agentType}` : `${endpoint}`
  const res = await axiosInstance.post<{ assessmentId: string }>(url, orderAssessmentBody)
  return res
}
