import { CustomerDemoHub } from "@/features/CustomerDemoHub"
import { Assessment } from "@/pages/Assessment/Assessment"
import { Home } from "@/pages/Home/Home"

const defaultRoutes = [
  {
    path: "/:region/assessments/:assessmentId",
    component: Assessment
  },
  {
    path: "/:region/cohorts/:cohortId",
    component: Assessment
  },
  {
    path: "/:region/jobs/:jobRequisitionShortcode",
    component: Assessment
  },
  {
    path: "/:region/applications/:applicationShortcode",
    component: Assessment
  }
]

const chatShortUrlRoutes = [
  {
    path: "/:region/:applicationShortcode",
    component: Assessment
  }
]
export const getRoutes = (env: string) => {
  const routes = [...defaultRoutes, ...chatShortUrlRoutes]
  if (env !== "production") {
    routes.push(
      {
        path: "/region/:region/customers/:customerId/vacancies/:externalCohortId/masterId/:predictorMasterId",
        component: CustomerDemoHub
      },
      {
        path: "/",
        component: Home
      }
    )
  }
  return routes
}
