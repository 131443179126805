import { Input, Select } from "antd"
import React, { useState } from "react"

import styles from "./style.module.less"

const { Option } = Select

import { postOrderAssessment } from "@/apis/FIApis"
import { Loading } from "@/components/Loading"

import PHBackgroundImage from "../../asset/Bias-HandBook-Download-Space.png"
import { getEnv } from "../ChatBotInterview/utils"
import {
  AssessmentType,
  CustomerEnvMap,
  CustomerItem,
  CustomerRegionMap,
  customerRegionMap,
  generateMockOrderAssessmentBody,
  Region,
  regionList
} from "./utils"

const defaultCustomerIndex = 0
const defaultRegionIndex = 0
const env = getEnv(window.location.host) as keyof CustomerEnvMap

export const Introduce = () => {
  const [showLoading, setShowLoading] = useState(false)

  const [candidateName, setCandidateName] = useState("")
  const [candidateEmail, setCandidateEmail] = useState("")
  const [customerList, setCustomerList] = useState(() => {
    const customerRegionMapKey = regionList[defaultRegionIndex].displayName.toLowerCase()
    const customerList = customerRegionMap[customerRegionMapKey as keyof CustomerRegionMap][env]
    return customerList
  })
  const [region, setRegion] = useState(regionList[defaultRegionIndex])
  const [customerItem, setCustomerItem] = useState<CustomerItem>(customerList[defaultCustomerIndex])

  const orderCIAssessment = async () => {
    const orderAssessmentBody = generateMockOrderAssessmentBody({
      candidateName,
      candidateEmail,
      customer: customerItem.customerInfo,
      type: AssessmentType.FI
    })
    const res = await postOrderAssessment({
      orderAssessmentBody: orderAssessmentBody,
      agentType: "dynamic",
      region: region.code
    })
    return res
  }

  const redirectToAssessment = (assessmentId: string) => {
    window.location.href = `${window.location.origin}/${region.code}/assessments/${assessmentId}`
  }

  const orderFIAssessment = async () => {
    const orderAssessmentBody = generateMockOrderAssessmentBody({
      candidateName,
      candidateEmail,
      customer: customerItem.customerInfo,
      type: AssessmentType.FI
    })
    const res = await postOrderAssessment({ orderAssessmentBody: orderAssessmentBody, region: region.code })
    return res
  }

  const orderSIAssessment = async () => {
    const orderAssessmentBody = generateMockOrderAssessmentBody({
      candidateName,
      candidateEmail,
      customer: customerItem.customerInfo,
      type: AssessmentType.SI
    })
    const res = await postOrderAssessment({ orderAssessmentBody: orderAssessmentBody, region: region.code })
    return res
  }

  const jumpToCIAssessment = async () => {
    if (!candidateName) {
      alert("Your full name is required!")
      return
    }
    setShowLoading(true)
    const orderRes = await orderCIAssessment()
    setShowLoading(false)
    redirectToAssessment(orderRes?.data?.assessmentId)
  }
  const jumpToFIAssessment = async () => {
    if (!candidateName) {
      alert("Your full name is required!")
      return
    }
    setShowLoading(true)
    const orderRes = await orderFIAssessment()
    setShowLoading(false)
    redirectToAssessment(orderRes?.data?.assessmentId)
  }

  const jumpToSIAssessment = async () => {
    if (!candidateName) {
      alert("Your full name is required!")
      return
    }
    setShowLoading(true)
    const orderRes = await orderSIAssessment()
    setShowLoading(false)
    redirectToAssessment(orderRes?.data?.assessmentId)
  }

  return (
    <div className={styles["main"]}>
      {showLoading && (
        <div className={styles["loading-box"]}>
          <Loading color="#f92c5e" />
        </div>
      )}
      <img className={styles["bg"]} alt="PredictiveHire bias handbook space background image" src={PHBackgroundImage} />
      <div className={styles["content-box"]}>
        <div className={styles["content"]}>
          <h1 className={styles["title"]}>Welcome to the Sapia Innovation Hub</h1>
          <p>Sapia is committed to the continuous innovation of data driven Ai solutions.</p>
          <p>Test drive our innovation prototypes here.</p>
          <div className={styles["handle-btn-box"]}>
            <Select
              onChange={(val: number) => {
                setRegion(regionList[val])
                const customerList =
                  customerRegionMap[regionList[val].displayName.toLowerCase() as keyof CustomerRegionMap][env]
                setCustomerList(customerList)
                setCustomerItem(customerList[defaultCustomerIndex])
              }}
              style={{ width: 200 }}
              defaultValue={defaultRegionIndex}
              data-cy="selectRegionButton"
            >
              {regionList.map((region: Region, index) => {
                return (
                  <Option id={region?.id} key={region?.id} value={index}>
                    {region.displayName}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className={styles["handle-btn-box"]}>
            <Select
              onChange={(val: number) => {
                setCustomerItem(customerList[val])
              }}
              style={{ width: 200 }}
              defaultValue={defaultCustomerIndex}
              data-cy="selectCustomerButton"
            >
              {customerList.map((item, index: number) => {
                return (
                  <Option id={item?.id} key={item?.id} value={index}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className={styles["handle-btn-box"]}>
            <Input
              placeholder="Enter your full name"
              onChange={e => setCandidateName(e.target.value)}
              style={{ width: 200 }}
              data-cy="usernameInput"
            />
          </div>
          <div className={styles["handle-btn-box"]}>
            <Input
              placeholder="Enter your email"
              onChange={e => setCandidateEmail(e.target.value)}
              style={{ width: 200 }}
              data-cy="emailInput"
            />
          </div>
          <div className={styles["handle-btn-box"]}>
            <button data-cy="selectFiButton" className={styles["demo-button"]} onClick={jumpToFIAssessment}>
              TAKE THE CHAT INTERVIEW DEMO
            </button>
            <button data-cy="selectCiButton" className={styles["demo-button"]} onClick={jumpToCIAssessment}>
              TAKE THE COACHING INSIGHTS DEMO
            </button>
            <button data-cy="selectSiButton" className={styles["demo-button"]} onClick={jumpToSIAssessment}>
              TAKE THE VIDEO INTERVIEW DEMO
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
